import {
  ref,
  readonly,
} from 'vue';
import Http from '@/services/http';
import useDomo from '@/use/domo';
import useAnalytics from '@/use/analytics';
import useStudent from '@/use/student';

const { domo } = useDomo();
const { trackEvent, amplitude } = useAnalytics();
const { trapStudent } = useStudent();


// Properties
const attemptedEmails = ref([]);
const isEmailError = ref(false);
const isEmailRateLimitError = ref(false);
const isSubmittingEmail = ref(false);


// Methods
async function remoteValidateEmail(session) {
  try {
    const { data } = await Http.post('/api/free/v2/emailCheck', {
      affiliateCode: session.affiliateCode ?? null,
      email: session.email.value,
      lang: domo.lang,
      pathName: window.location.pathname,
      uniqueFormId: domo.uffid,
      startType: session.start_type,
      country: window.Domo.country,
      appstore: session?.appstore ?? null,
    });

    return data;
  } catch (error) {
    console.error('ERROR VALIDATING EMAIL: ', error);

    return 500;
  }
}

async function sendEmailConfirmationCode(email, isRetry = false) {
  try {
    await Http.post('/api/free/code/send', {
      email,
      isRetry,
      uniqueFormId: domo.uffid,
      lang: domo.lang,
      navigatorLanguage: window.navigator.language,
      pathName: window.location.pathname,
      amplitudeUserId: amplitude.userId,
      amplitudeDeviceId: amplitude.deviceId,
    });
  } catch (error) {
    console.error('ERROR SENDING CONFIRMATION CODE: ', error);
    isEmailRateLimitError.value = [429].includes(error.response?.status);
    isEmailError.value = true;
    throw new Error(error);
  }
}

async function sendExistingUserEmail(email) {
  try {
    await Http.post('/api/free/userExists', {
      email,
      pathName: window.location.pathname,
      uniqueFormId: domo.uffid,
    });
  } catch (error) {
    console.error('ERROR SENDING EXISTING USER EMAIL: ', error);
  }
}

function redirectCompetitor() {
  window.location = 'https://www.domo.com/form/talk-to-sales';
}

function isEmailAttempted(email) {
  return attemptedEmails.value.includes(email.toLowerCase());
}

async function submitEmail({
  session,
  isRetry = false,
  onSuccess,
  onError,
}) {
  if (
    !session.email.isValidClientSide
    || isEmailAttempted(session.email.value)
  ) {
    isEmailError.value = true;
    isSubmittingEmail.value = false;
    if (onError) onError();
    return;
  }

  isEmailError.value = false;
  isEmailRateLimitError.value = false;
  isSubmittingEmail.value = true;

  const { code, startType, university } = await remoteValidateEmail(session);

  const isEmailValid = [200].includes(code);
  const isEmailInvalid = [400, 401, 495, 500].includes(code);
  const isEmailExisting = [496, 409].includes(code);
  const isEmailPersonal = [497].includes(code);
  const isEmailCompetitor = [498, 499].includes(code);
  const isStudentEmailInvalid = [420].includes(code); // <- only received if flow === 'student'
  const isStudentFlow = startType === 'student';

  if (isEmailInvalid || isEmailPersonal || isStudentEmailInvalid) {
    attemptedEmails.value.push(session.email.value.toLowerCase());
    isEmailError.value = true;
    isSubmittingEmail.value = false;
    if (onError) onError();
    return;
  }

  if (isEmailCompetitor) {
    redirectCompetitor();
    return;
  }

  if (isEmailExisting) {
    await sendExistingUserEmail(session.email.value);
    isSubmittingEmail.value = false;
    if (onSuccess) onSuccess();
    return;
  }

  session.university = university;

  const sendCodeWithCallbacks = async () => {
    try {
      await sendEmailConfirmationCode(session.email.value, isRetry);
      isSubmittingEmail.value = false;
      if (onSuccess) onSuccess();
    } catch {
      isSubmittingEmail.value = false;
      if (onError) onError();
    }
  };

  if (!isStudentFlow && !!university) {
    trapStudent(session.email.value, sendCodeWithCallbacks);
    return;
  }

  if (isEmailValid) {
    await sendCodeWithCallbacks();
  }
}

async function prePopEmail(flow, session, route) {
  let prePopEmailRedirect = null;
  const { email, ...queryWithoutEmail } = route.query ?? {};

  if (!email) {
    return {
      session,
      prePopEmailRedirect,
    };
  }

  session.email.value = route.query.email;

  try {
    await Http.post('/api/free/clearSession');

    await submitEmail({
      flowName: flow.id,
      session,
      onSuccess: () => {
        trackEvent('submitted email address', flow.steps[0], session);

        prePopEmailRedirect = {
          name: 'step',
          params: {
            lang: route.params.lang || '',
            flowName: route.params.flowName,
            stepName: 'confirm',
          },
          query: queryWithoutEmail,
          replace: true,
        };
      },
      onError: () => {
        prePopEmailRedirect = {
          name: 'step',
          params: {
            lang: route.params.lang || '',
            flowName: route.params.flowName,
            stepName: '',
          },
          query: queryWithoutEmail,
          replace: true,
        };
      },
    });
  } catch (error) {
    console.error('ERROR PRE-POPPPING EMAIL: ', error);
  }

  return {
    session,
    prePopEmailRedirect,
  };
}


// Exports
export default function useEmail() {
  return {
    isEmailError: readonly(isEmailError),
    isEmailRateLimitError: readonly(isEmailRateLimitError),
    isSubmittingEmail: readonly(isSubmittingEmail),
    submitEmail,
    prePopEmail,
  };
}
