import {
  ref,
  readonly,
} from 'vue';

const isTrapStudentModalOpen = ref(false);
const trappedEmail = ref('');
const trappedCallback = ref(null);

export default function useStudent() {
  function trapStudent(email, onNoCallback) {
    isTrapStudentModalOpen.value = true;
    trappedEmail.value = email;
    trappedCallback.value = onNoCallback ?? null;
  }

  function handleNoStudent() {
    isTrapStudentModalOpen.value = false;
    return trappedCallback?.value();
  }

  async function handleYesStudent(route) {
    window.location = `${window.location.origin}${route.params.lang}/start/student/confirm?email=${trappedEmail.value}`;
  }

  return {
    isTrapStudentModalOpen: readonly(isTrapStudentModalOpen),
    trappedEmail: readonly(trappedEmail),
    trapStudent,
    handleNoStudent,
    handleYesStudent,
  };
}
