export default {
  // CTAS
  ctas: {
    alreadyDomoUser: 'Sei già un utente Domo?',
    createAccount: 'CREA ACCOUNT',
    launchDomoNow: 'Launch Domo Now',
    next: 'Avanti',
    finishAndLaunchDomo: 'Termina e avvia Domo',
    signIn: 'Accedi',
    signUp: 'CREA ACCOUNT',
  },

  // SHARED
  shared: {
    businessEmail: 'E-mail aziendale',
    businessEmailError: 'Please enter a valid business email address',
    changeEmail: 'Modifica l\'indirizzo e-mail.',
    checkSpam: 'Controlla nella cartella di spam.',
    clickToResend: 'click to resend',
    company: 'Azienda',
    companyNameIsRequired: '* Company name is required',
    confirmationCode: 'Codice di conferma',
    confirmationCodeError: 'That code doesn\'t match the code we sent to {email}',
    contactForAssistance: "Please contact <a class='color-link-blue link' href='mailto:domofree{'@'}domo.com' target='_blank'>domofree{'@'}domo.com</a> for assistance.",
    country: 'Paese',
    departmentOrTeam: 'Reparto o team',
    firstName: 'Nome',
    firstNameIsRequired: '* Il nome è obbligatorio',
    jobTitle: 'Titolo di lavoro',
    jobTitleIsRequired: '* Job title is required',
    lastName: 'Cognome',
    lastNameIsRequired: '* Il cognome è obbligatorio',
    launchingDomo: 'Launching Domo',
    launchingInSeconds: 'Launching Domo in {seconds} seconds',
    notReceivedEmail: 'Non hai ricevuto la nostra e-mail?',
    notSeeingYourRole: 'Il tuo ruolo non è nell\'elenco?',
    notSeeingYourTeam: 'Il tuo team non è nell\'elenco?',
    notSeeingYourUseCase: 'Not seeing your use case?',
    password: 'Password',
    passwordLowercase: '1 lowercase',
    passwordLowercaseRequired: 'Inserisci almeno 1 lettera minuscola',
    passwordMinimum: '8 characters minimum',
    passwordMinimumRequired: 'Deve avere almeno 8 caratteri',
    passwordNumber: '1 number',
    passwordNumberRequired: 'Inserisci almeno 1 numero',
    passwordRequirements: "Passwords must contain at least 8 characters, lowercase and uppercase characters, a number, and at least 1 special character ({'!,@,#,$'}).",
    passwordRequirementsLabel: 'Passwords must contain:',
    passwordSpecial: "1 special character (ex: {'@$!#'})",
    passwordSpecialRequired: 'At least 1 special character required',
    passwordUppercase: '1 uppercase',
    passwordUppercaseRequired: 'Inserisci almeno 1 lettera maiuscola',
    phone: 'Telefono',
    pleaseCompleteFields: 'Compila tutti i campi obbligatori',
    pleaseEnterValidPhoneNumber: '* Inserisci un numero di telefono valido',
    primaryObjectiveRequired: '* Primary objective is required',
    progressLabel: 'Passaggio {currentStep} di {totalSteps}',
    rateLimitError: 'Too many requests, please try again later',
    resendsRemaining: '({attempts} attempt remaining) | ({attempts} attempts remaining)',
    resendError: 'Unable to resend code, please try again later',
    resendingCode: 'Resending confirmation code',
    resentCode: 'Confirmation code sent',
    role: 'Ruolo',
    roleIsRequired: '* Role is required',
    search: 'Search',
    teamIsRequired: '* Team is required',
    tellUsHowYouWantToUseDomo: 'Tell us how you want to use Domo',
    tellUsWhatYouDo: 'Raccontaci cosa fai.',
    trustedBy: 'CLIENTI SODDISFATTI',
    useCase: 'Use case',
    walkthroughIsRequired: 'Walk-through response is required',
    wantToScheduleLater: 'Want to schedule later?',
    whatBestDescribesYourRole: 'Quale frase descrive al meglio che ruolo hai con i dati?',
    whatIsYourJobTitle: 'Qual è il tuo titolo di lavoro?',
    whatTeamAreYouOn: 'Di quale team fai parte?',
    whatTeamDoYouWorkOn: 'In quale team lavori?',
    whatWouldYouLikeToAccomplish: 'What would you like to accomplish with Domo?',
    wrongAddress: 'È l\'indirizzo sbagliato?',
  },

  // LEGAL
  legal: {
    acknowledgePrivacyPolicy: 'Creando un account gratuito, l\'utente riconosce l\'<a class="__legal-link font-semi-bold nowrap" href="https://www.domo.com/company/privacy-policy" target="_blank">Informativa sulla privacy</a> di Domo.',
    agreeToTerms: 'I agree to the <a class="underline" href="https://www.domo.com/company/service-terms" target="_blank">Domo Software as a Service Agreement</a> and acknowledge Domo\'s <a class="underline" href="https://www.domo.com/company/privacy-policy" target="_blank">Privacy Notice</a>.',
    autoAgreeToTerms: 'By clicking "next" you agree to the <a class="underline" href="https://www.domo.com/company/service-terms" target="_blank">Domo Software as a Service Agreement</a> and acknowledge Domo\'s <a class="underline" href="https://www.domo.com/company/privacy-policy" target="_blank">Privacy Notice</a>.',
    autoConsent: 'Domo potrebbe inviarti informazioni e offerte sui prodotti e servizi Domo per le aziende.',
    managePreferences: 'To withdraw consent or manage your subscription preferences, <a class="underline" href="https://www.domo.com/unsubscribe" target="_blank">click here</a>.',
    patents: 'Patents',
    privacyCookiePolicy: 'Privacy and Cookie Policy',
    privacyNotice: 'Privacy Notice',
    requestConsent: 'Allow Domo to send you information and offers about Domo products and services for businesses.',
    termsOfService: 'Terms of Service',
  },

  // PRIVACY LINKS
  privacies: [
    {
      label: 'Cookie Preferences',
      href: 'https://domo.com/company/cookies',
    },
    {
      label: 'Privacy & Cookie Policy',
      href: 'https://domo.com/company/privacy-policy',
    },
    {
      label: 'Patents',
      href: 'https://domo.com/company/patents',
    },
  ],

  // FLOWS
  flows: {

    // SHARED
    shared: {
      steps: {
        email: {
          title: 'Sign up for Domo free.',
          subtitle: null,
        },
        confirm: {
          title: 'Controlla la tua e-mail.',
          subtitle: 'Abbiamo inviato un codice di conferma a sei cifre a {email} Inseriscilo subito prima che scada.',
        },
        profile: {
          title: 'Benvenuto in Domo.',
          subtitle: 'Stiamo configurando il tuo account. Nel frattempo, perché non completi il tuo profilo Domo?',
          stepperLabel: 'Complete profile',
        },
        personal: {
          title: 'Raccontaci qualcosa di te.',
          subtitle: 'Ora che hai terminato la registrazione, personalizza la tua esperienza.',
          stepperLabel: 'Personalize',
        },
        walkthrough: {
          title: 'Desideri vedere un tour guidato personalizzato?',
          subtitle: null,
          stepperLabel: 'Launch Domo',
        },
      },
    },

    // FREE
    free: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Prova Domo gratis.',
          subtitle: 'Connetti, trasforma e visualizza i tuoi dati in Domo. Crea ora il tuo account Domo.',
          features: [
            '<b>Effettuare una configurazione rapida.</b> Lanciare e potenziare i tuoi dati in pochi minuti.',
            '<b>Connettere e trasformare.</b> Connetti e normalizza i dati in modo sicuro.',
            '<b>Visualizzare e analizzare.</b> Crea e condividi KPI in tempo reale.',
            '<b>Crea ed estendi.</b> Crea app personalizzate e condividi i dati.',
          ],
        },
      },
    },

    // FREEMIUM
    freemium: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Crea ora il tuo account gratuito e accedi a esso per sempre.',
          subtitle: 'Tutte le funzionalità, numero illimitato di utenti, nessun limite di tempo e nessuna carta di credito necessaria.',
          features: [
            '<b>Utenti illimitati:</b> Non c\'è mai limite di posti.',
            '<b>Tutte le funzionalità incluse:</b> Accedi a tutte le oltre 50 funzionalità: dashboard BI, integrazione dei dati, strumenti per la creazione di app, gestione dei modelli AI, sicurezza e altro ancora.',
            '<b>Forza lavoro migliorata:</b> Consenti a tutti gli utenti di accedere ai dati in tempo reale per prendere decisioni rapide e intelligenti che trasformano l\'azienda.',
            '<b>Prezzi flessibili:</b> Quando rinunci alla versione gratuita, puoi ottenere prezzi convenienti in base all\'uso che fai della piattaforma.',
          ],
        },
      },
    },

    // SNOWFLAKE
    snowflake: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Try Domo for free.',
          subtitle: 'Simplify data management and get actionable intelligence faster with Snowflake and Domo.',
          features: [
            '<b>Rapid Setup:</b> Power up your Snowflake + Domo environment in minutes.',
            '<b>Connect and Enrich Data:</b> Enrich your Snowflake data foundation via Domo’s robust integration library of 1000+ connectors and on-premises capabilities.',
            '<b>Prepare Data for Analysis:</b> Quickly prepare data with robust ETL capabilities via push down SQL to Snowflake.',
            '<b>Turn Insights into Action:</b> Build action-oriented solutions via BI visualizations and low-code applications accessible to all users at no cost.',
          ],
        },
      },
    },

    // STUDENT
    student: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Domo for Students.',
          studentEmailAddress: 'Student email address',
          eligibleLabel: 'I am actively enrolled as a student at an accredited U.S. college or university and am at least 18 years old.',
          eligibleError: 'Please verify your eligibility',
          studentEmailError: "The student email address provided is either invalid or not associated with a known university. Please contact <a class='underline' href='mailto:highereducation{'@'}domo.com'>highereducation{'@'}domo.com</a> for support.",
          subtitle: 'Try Domo for free for one year and learn critical data skills. Domo brings together data, systems, and people for a digitally connected business. Learning how businesses use data in a connected world will give you the skills you need to stand out and land the perfect job.',
          featuresLabel: 'With Domo\'s free trial for actively enrolled students, you will have access to exciting opportunities, just for you.',
          features: [
            '<b>Get Domo Certified:</b> Gain experience using the Domo product to help you complete our professional-level certification.',
            '<b>Learn the skills employers are looking for:</b> Domo comes packed with hundreds of training courses and videos to teach you all the skills you need to land the perfect job: data analysis, data storytelling, visualization strategies, SQL, ETL, "big data", app development, and more.',
          ],
        },
        profile: {
          subtitle: 'Please complete the below so that we are able to verify your eligibility for a Domo student account.',
          country: 'Country of residence',
          agreeToTerms: 'I agree to the <a class="underline" href="https://web-assets.domo.com/blog/wp-content/uploads/2024/09/Domo-Student-Instance_Sept2024v2.pdf" target="_blank">Domo for Students Service Agreement</a> and acknowledge Domo\'s <a class="underline" href="https://www.domo.com/company/privacy-policy" target="_blank">Privacy Notice</a>.',
        },
        enrollment: {
          title: 'Tell us about your enrollment at {university}',
          graduationLabel: 'When is your expected graduation date?',
          month: 'Month',
          stepperLabel: 'Enrollment',
          studyProgramLabel: 'What is your program of study?',
          studyProgramPlaceholder: 'Program of study',
          year: 'Year',
        },
      },
    },

    // DEVELOPER
    developer: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Get your developer trial.',
          subtitle: 'Start your free trial to start developing on Domo today.',
          features: [
            'Full-featured, dev-ready environment',
            'Connector IDE and data API ready',
            'Pre-loaded with sample data & cards',
            'Extensive documentation and guides',
            'SDK for building custom apps',
          ],
          finePrint: 'Free for customers, partners, and prospective partners.<br/><br/>By requesting a developer trial you agree to <a class="underline" target="_blank" href="https://web-assets.domo.com/blog/wp-content/uploads/2021/09/Domo-Developer-Trial-Agreement-2021.pdf">Domo\'s terms of service.</a>',
        },
      },
    },

    // IBM
    ibm: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Prova Domo gratis.',
          subtitle: 'Connetti, trasforma e visualizza i tuoi dati in Domo. Crea ora il tuo account Domo.',
          features: [
            '<b>Effettuare una configurazione rapida.</b> Lanciare e potenziare i tuoi dati in pochi minuti.',
            '<b>Connettere e trasformare.</b> Connetti e normalizza i dati in modo sicuro.',
            '<b>Visualizzare e analizzare.</b> Crea e condividi KPI in tempo reale.',
            '<b>Crea ed estendi.</b> Crea app personalizzate e condividi i dati.',
          ],
        },
        useCase: {
          title: 'Tell us about your use case.',
          subtitle: 'Domo can do a lot—help us understand what you hope to accomplish.',
        },
      },
    },
  },

  // OPTIONS
  companySizes: [
    {
      value: '5000+',
      label: '5000+',
    },
    {
      value: '1000-4999',
      label: '1000-4999',
    },
    {
      value: '500-999',
      label: '500-999',
    },
    {
      value: '100-499',
      label: '100-499',
    },
    {
      value: '50-99',
      label: '50-99',
    },
    {
      value: '1-49',
      label: '1-49',
    },
  ],

  departments: [
    {
      value: 'BI/Analytics',
      label: 'BI/analisi',
    },
    {
      value: 'IT/Engineering',
      label: 'IT/Ingegneria',
    },
    {
      value: 'Finance',
      label: 'Finanze',
    },
    {
      value: 'Human Resources',
      label: 'Risorse umane',
    },
    {
      value: 'Marketing',
      label: 'Marketing',
    },
    {
      value: 'Operations',
      label: 'Operations',
    },
    {
      value: 'Sales',
      label: 'Vendite',
    },
    {
      value: 'Support/Services',
      label: 'Supporto/Servizi',
    },
  ],

  months: [
    {
      value: 'January',
      label: 'January',
    },
    {
      value: 'February',
      label: 'February',
    },
    {
      value: 'March',
      label: 'March',
    },
    {
      value: 'April',
      label: 'April',
    },
    {
      value: 'May',
      label: 'May',
    },
    {
      value: 'June',
      label: 'June',
    },
    {
      value: 'July',
      label: 'July',
    },
    {
      value: 'August',
      label: 'August',
    },
    {
      value: 'September',
      label: 'September',
    },
    {
      value: 'October',
      label: 'October',
    },
    {
      value: 'November',
      label: 'November',
    },
    {
      value: 'December',
      label: 'December',
    },
  ],

  studentPrograms: [
    {
      value: 'Business Administration',
      label: 'Business Administration',
    },
    {
      value: 'Business Management',
      label: 'Business Management',
    },
    {
      value: 'Marketing',
      label: 'Marketing',
    },
    {
      value: 'Finance',
      label: 'Finance',
    },
    {
      value: 'Accounting',
      label: 'Accounting',
    },
    {
      value: 'Economics',
      label: 'Economics',
    },
    {
      value: 'Human Resource Management',
      label: 'Human Resource Management',
    },
    {
      value: 'Education Administration',
      label: 'Education Administration',
    },
    {
      value: 'Information Systems and Technologies',
      label: 'Information Systems and Technologies',
    },
    {
      value: 'Healthcare Management',
      label: 'Healthcare Management',
    },
    {
      value: 'EcPublic Policyonomics',
      label: 'Public Policy',
    },
    {
      value: 'Environmental Science',
      label: 'Environmental Science',
    },
    {
      value: 'Social Studies & Psychology',
      label: 'Social Studies & Psychology',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ],

  roles: [
    {
      value: 'Data Creator / Analyst',
      label: '<b>Data Creator/Analyst</b><br><span class="color-mute font-14">Crei e fornisci report agli altri in qualità di Analyst o professionista dei dati</span>',
      icon: 'graph-report',
    },
    {
      value: 'Data Consumer',
      label: '<b>Data Consumer</b><br><span class="color-mute font-14">In linea di massima utilizzi dei report creati da altri</span>',
      icon: 'graph-computer',
    },
    {
      value: 'Data Engineer',
      label: '<b>Data Engineer</b><br><span class="color-mute font-14">Integri, costruisci e governi delle risorse di dati in tutta l\'azienda</span>',
      icon: 'cogs',
    },
  ],

  useCases: [
    {
      value: 'Create real-time, interactive dashboards',
      label: 'Create real-time, interactive dashboards',
      icon: 'graph-computer',
    },
    {
      value: 'Make data more accessible for teams and execs',
      label: 'Make data more accessible for teams and execs',
      icon: 'users-circle',
    },
    {
      value: 'Integrate and analyze data from various sources',
      label: 'Integrate and analyze data from various sources',
      icon: 'graph-tree',
    },
    {
      value: 'Centralize all data and reporting',
      label: 'Centralize all data and reporting',
      icon: 'graph-line',
    },
    {
      value: 'Automate reporting',
      label: 'Automate reporting',
      icon: 'webpage',
    },
    {
      value: 'Provide dashboards to customers and partners',
      label: 'Provide dashboards to customers and partners',
      icon: 'hand-click',
    },
  ],

  poc: [
    {
      value: true,
      label: 'Sì, desidero vedere un tour guidato gratuito e personalizzato con un esperto di prodotti Domo.',
    },
    {
      value: false,
      label: 'No, vorrei esplorare da solo.',
    },
  ],

  countries: [
    {
      value: 'US',
      label: 'United States of America',
    },
    {
      value: 'GB',
      label: 'United Kingdom',
    },
    {
      value: 'CA',
      label: 'Canada',
    },
    {
      value: 'DE',
      label: 'Germany',
    },
    {
      value: 'JP',
      label: 'Japan',
    },
    {
      value: 'AF',
      label: 'Afghanistan',
    },
    {
      value: 'AX',
      label: 'Aland Islands',
    },
    {
      value: 'AL',
      label: 'Albania',
    },
    {
      value: 'DZ',
      label: 'Algeria',
    },
    {
      value: 'AS',
      label: 'American Samoa',
    },
    {
      value: 'AD',
      label: 'Andorra',
    },
    {
      value: 'AO',
      label: 'Angola',
    },
    {
      value: 'AI',
      label: 'Anguilla',
    },
    {
      value: 'AQ',
      label: 'Antarctica',
    },
    {
      value: 'AG',
      label: 'Antigua and Barbuda',
    },
    {
      value: 'AR',
      label: 'Argentina',
    },
    {
      value: 'AM',
      label: 'Armenia',
    },
    {
      value: 'AW',
      label: 'Aruba',
    },
    {
      value: 'AU',
      label: 'Australia',
    },
    {
      value: 'AT',
      label: 'Austria',
    },
    {
      value: 'AZ',
      label: 'Azerbaijan',
    },
    {
      value: 'BS',
      label: 'Bahamas',
    },
    {
      value: 'BH',
      label: 'Bahrain',
    },
    {
      value: 'BD',
      label: 'Bangladesh',
    },
    {
      value: 'BB',
      label: 'Barbados',
    },
    {
      value: 'BY',
      label: 'Belarus',
    },
    {
      value: 'BE',
      label: 'Belgium',
    },
    {
      value: 'BZ',
      label: 'Belize',
    },
    {
      value: 'BJ',
      label: 'Benin',
    },
    {
      value: 'BM',
      label: 'Bermuda',
    },
    {
      value: 'BT',
      label: 'Bhutan',
    },
    {
      value: 'BO',
      label: 'Bolivia',
    },
    {
      value: 'BA',
      label: 'Bosnia and Herzegovina',
    },
    {
      value: 'BW',
      label: 'Botswana',
    },
    {
      value: 'BV',
      label: 'Bouvet Island',
    },
    {
      value: 'BR',
      label: 'Brazil',
    },
    {
      value: 'IO',
      label: 'British Indian Ocean Territory',
    },
    {
      value: 'BN',
      label: 'Brunei Darussalam',
    },
    {
      value: 'BG',
      label: 'Bulgaria',
    },
    {
      value: 'BF',
      label: 'Burkina Faso',
    },
    {
      value: 'BI',
      label: 'Burundi',
    },
    {
      value: 'KH',
      label: 'Cambodia',
    },
    {
      value: 'CM',
      label: 'Cameroon',
    },
    {
      value: 'CV',
      label: 'Cape Verde',
    },
    {
      value: 'KY',
      label: 'Cayman Islands',
    },
    {
      value: 'CF',
      label: 'Central African Republic',
    },
    {
      value: 'TD',
      label: 'Chad',
    },
    {
      value: 'CL',
      label: 'Chile',
    },
    {
      value: 'CN',
      label: 'China',
    },
    {
      value: 'CX',
      label: 'Christmas Island',
    },
    {
      value: 'CC',
      label: 'Cocos (Keeling) Islands',
    },
    {
      value: 'CO',
      label: 'Colombia',
    },
    {
      value: 'KM',
      label: 'Comoros',
    },
    {
      value: 'CG',
      label: 'Congo',
    },
    {
      value: 'CD',
      label: 'Congo, Democratic Republic',
    },
    {
      value: 'CK',
      label: 'Cook Islands',
    },
    {
      value: 'CR',
      label: 'Costa Rica',
    },
    {
      value: 'CI',
      label: 'Cote D\'ivoire',
    },
    {
      value: 'HR',
      label: 'Croatia',
    },
    {
      value: 'CU',
      label: 'Cuba',
    },
    {
      value: 'CY',
      label: 'Cyprus',
    },
    {
      value: 'CZ',
      label: 'Czech Republic',
    },
    {
      value: 'DK',
      label: 'Denmark',
    },
    {
      value: 'DJ',
      label: 'Djibouti',
    },
    {
      value: 'DM',
      label: 'Dominica',
    },
    {
      value: 'DO',
      label: 'Dominican Republic',
    },
    {
      value: 'EC',
      label: 'Ecuador',
    },
    {
      value: 'EG',
      label: 'Egypt',
    },
    {
      value: 'SV',
      label: 'El Salvador',
    },
    {
      value: 'GQ',
      label: 'Equatorial Guinea',
    },
    {
      value: 'ER',
      label: 'Eritrea',
    },
    {
      value: 'EE',
      label: 'Estonia',
    },
    {
      value: 'ET',
      label: 'Ethiopia',
    },
    {
      value: 'FK',
      label: 'Falkland Islands (Malvinas)',
    },
    {
      value: 'FO',
      label: 'Faroe Islands',
    },
    {
      value: 'FJ',
      label: 'Fiji',
    },
    {
      value: 'FI',
      label: 'Finland',
    },
    {
      value: 'FR',
      label: 'France',
    },
    {
      value: 'GF',
      label: 'French Guiana',
    },
    {
      value: 'PF',
      label: 'French Polynesia',
    },
    {
      value: 'TF',
      label: 'French Southern Territories',
    },
    {
      value: 'GA',
      label: 'Gabon',
    },
    {
      value: 'GM',
      label: 'Gambia',
    },
    {
      value: 'GE',
      label: 'Georgia',
    },
    {
      value: 'GH',
      label: 'Ghana',
    },
    {
      value: 'GI',
      label: 'Gibraltar',
    },
    {
      value: 'GR',
      label: 'Greece',
    },
    {
      value: 'GL',
      label: 'Greenland',
    },
    {
      value: 'GD',
      label: 'Grenada',
    },
    {
      value: 'GP',
      label: 'Guadeloupe',
    },
    {
      value: 'GU',
      label: 'Guam',
    },
    {
      value: 'GT',
      label: 'Guatemala',
    },
    {
      value: 'GG',
      label: 'Guernsey',
    },
    {
      value: 'GN',
      label: 'Guinea',
    },
    {
      value: 'GW',
      label: 'Guinea-bissau',
    },
    {
      value: 'GY',
      label: 'Guyana',
    },
    {
      value: 'HT',
      label: 'Haiti',
    },
    {
      value: 'HM',
      label: 'Heard Island and Mcdonald Islands',
    },
    {
      value: 'VA',
      label: 'Holy See (Vatican City State)',
    },
    {
      value: 'HN',
      label: 'Honduras',
    },
    {
      value: 'HK',
      label: 'Hong Kong',
    },
    {
      value: 'HU',
      label: 'Hungary',
    },
    {
      value: 'IS',
      label: 'Iceland',
    },
    {
      value: 'IN',
      label: 'India',
    },
    {
      value: 'ID',
      label: 'Indonesia',
    },
    {
      value: 'IR',
      label: 'Iran, Islamic Republic',
    },
    {
      value: 'IQ',
      label: 'Iraq',
    },
    {
      value: 'IE',
      label: 'Ireland',
    },
    {
      value: 'IM',
      label: 'Isle of Man',
    },
    {
      value: 'IL',
      label: 'Israel',
    },
    {
      value: 'IT',
      label: 'Italy',
    },
    {
      value: 'JM',
      label: 'Jamaica',
    },
    {
      value: 'JE',
      label: 'Jersey',
    },
    {
      value: 'JO',
      label: 'Jordan',
    },
    {
      value: 'KZ',
      label: 'Kazakhstan',
    },
    {
      value: 'KE',
      label: 'Kenya',
    },
    {
      value: 'KI',
      label: 'Kiribati',
    },
    {
      value: 'KP',
      label: 'Korea, Democratic People\'s Republic',
    },
    {
      value: 'KR',
      label: 'Korea, Republic of',
    },
    {
      value: 'KW',
      label: 'Kuwait',
    },
    {
      value: 'KG',
      label: 'Kyrgyzstan',
    },
    {
      value: 'LA',
      label: 'Lao People\'s Democratic Republic',
    },
    {
      value: 'LV',
      label: 'Latvia',
    },
    {
      value: 'LB',
      label: 'Lebanon',
    },
    {
      value: 'LS',
      label: 'Lesotho',
    },
    {
      value: 'LR',
      label: 'Liberia',
    },
    {
      value: 'LY',
      label: 'Libyan Arab Jamahiriya',
    },
    {
      value: 'LI',
      label: 'Liechtenstein',
    },
    {
      value: 'LT',
      label: 'Lithuania',
    },
    {
      value: 'LU',
      label: 'Luxembourg',
    },
    {
      value: 'MO',
      label: 'Macao',
    },
    {
      value: 'MK',
      label: 'Macedonia',
    },
    {
      value: 'MG',
      label: 'Madagascar',
    },
    {
      value: 'MW',
      label: 'Malawi',
    },
    {
      value: 'MY',
      label: 'Malaysia',
    },
    {
      value: 'MV',
      label: 'Maldives',
    },
    {
      value: 'ML',
      label: 'Mali',
    },
    {
      value: 'MT',
      label: 'Malta',
    },
    {
      value: 'MH',
      label: 'Marshall Islands',
    },
    {
      value: 'MQ',
      label: 'Martinique',
    },
    {
      value: 'MR',
      label: 'Mauritania',
    },
    {
      value: 'MU',
      label: 'Mauritius',
    },
    {
      value: 'YT',
      label: 'Mayotte',
    },
    {
      value: 'MX',
      label: 'Mexico',
    },
    {
      value: 'FM',
      label: 'Micronesia, Federated States',
    },
    {
      value: 'MD',
      label: 'Moldova, Republic',
    },
    {
      value: 'MC',
      label: 'Monaco',
    },
    {
      value: 'MN',
      label: 'Mongolia',
    },
    {
      value: 'ME',
      label: 'Montenegro',
    },
    {
      value: 'MS',
      label: 'Montserrat',
    },
    {
      value: 'MA',
      label: 'Morocco',
    },
    {
      value: 'MZ',
      label: 'Mozambique',
    },
    {
      value: 'MM',
      label: 'Myanmar',
    },
    {
      value: 'NA',
      label: 'Namibia',
    },
    {
      value: 'NR',
      label: 'Nauru',
    },
    {
      value: 'NP',
      label: 'Nepal',
    },
    {
      value: 'NL',
      label: 'Netherlands',
    },
    {
      value: 'AN',
      label: 'Netherlands Antilles',
    },
    {
      value: 'NC',
      label: 'New Caledonia',
    },
    {
      value: 'NZ',
      label: 'New Zealand',
    },
    {
      value: 'NI',
      label: 'Nicaragua',
    },
    {
      value: 'NE',
      label: 'Niger',
    },
    {
      value: 'NG',
      label: 'Nigeria',
    },
    {
      value: 'NU',
      label: 'Niue',
    },
    {
      value: 'NF',
      label: 'Norfolk Island',
    },
    {
      value: 'MP',
      label: 'Northern Mariana Islands',
    },
    {
      value: 'NO',
      label: 'Norway',
    },
    {
      value: 'OM',
      label: 'Oman',
    },
    {
      value: 'PK',
      label: 'Pakistan',
    },
    {
      value: 'PW',
      label: 'Palau',
    },
    {
      value: 'PS',
      label: 'Palestinian Territory, Occupied',
    },
    {
      value: 'PA',
      label: 'Panama',
    },
    {
      value: 'PG',
      label: 'Papua New Guinea',
    },
    {
      value: 'PY',
      label: 'Paraguay',
    },
    {
      value: 'PE',
      label: 'Peru',
    },
    {
      value: 'PH',
      label: 'Philippines',
    },
    {
      value: 'PN',
      label: 'Pitcairn',
    },
    {
      value: 'PL',
      label: 'Poland',
    },
    {
      value: 'PT',
      label: 'Portugal',
    },
    {
      value: 'PR',
      label: 'Puerto Rico',
    },
    {
      value: 'QA',
      label: 'Qatar',
    },
    {
      value: 'RE',
      label: 'Reunion',
    },
    {
      value: 'RO',
      label: 'Romania',
    },
    {
      value: 'RU',
      label: 'Russian Federation',
    },
    {
      value: 'RW',
      label: 'Rwanda',
    },
    {
      value: 'SH',
      label: 'Saint Helena',
    },
    {
      value: 'KN',
      label: 'Saint Kitts and Nevis',
    },
    {
      value: 'LC',
      label: 'Saint Lucia',
    },
    {
      value: 'PM',
      label: 'Saint Pierre and Miquelon',
    },
    {
      value: 'VC',
      label: 'Saint Vincent and The Grenadines',
    },
    {
      value: 'WS',
      label: 'Samoa',
    },
    {
      value: 'SM',
      label: 'San Marino',
    },
    {
      value: 'ST',
      label: 'Sao Tome and Principe',
    },
    {
      value: 'SA',
      label: 'Saudi Arabia',
    },
    {
      value: 'SN',
      label: 'Senegal',
    },
    {
      value: 'RS',
      label: 'Serbia',
    },
    {
      value: 'SC',
      label: 'Seychelles',
    },
    {
      value: 'SL',
      label: 'Sierra Leone',
    },
    {
      value: 'SG',
      label: 'Singapore',
    },
    {
      value: 'SK',
      label: 'Slovakia',
    },
    {
      value: 'SI',
      label: 'Slovenia',
    },
    {
      value: 'SB',
      label: 'Solomon Islands',
    },
    {
      value: 'SO',
      label: 'Somalia',
    },
    {
      value: 'ZA',
      label: 'South Africa',
    },
    {
      value: 'GS',
      label: 'South Georgia',
    },
    {
      value: 'ES',
      label: 'Spain',
    },
    {
      value: 'LK',
      label: 'Sri Lanka',
    },
    {
      value: 'SD',
      label: 'Sudan',
    },
    {
      value: 'SR',
      label: 'Suriname',
    },
    {
      value: 'SJ',
      label: 'Svalbard and Jan Mayen',
    },
    {
      value: 'SZ',
      label: 'Swaziland',
    },
    {
      value: 'SE',
      label: 'Sweden',
    },
    {
      value: 'CH',
      label: 'Switzerland',
    },
    {
      value: 'SY',
      label: 'Syrian Arab Republic',
    },
    {
      value: 'TW',
      label: 'Taiwan, Province of China',
    },
    {
      value: 'TJ',
      label: 'Tajikistan',
    },
    {
      value: 'TZ',
      label: 'Tanzania, United Republic',
    },
    {
      value: 'TH',
      label: 'Thailand',
    },
    {
      value: 'TL',
      label: 'Timor-leste',
    },
    {
      value: 'TG',
      label: 'Togo',
    },
    {
      value: 'TK',
      label: 'Tokelau',
    },
    {
      value: 'TO',
      label: 'Tonga',
    },
    {
      value: 'TT',
      label: 'Trinidad and Tobago',
    },
    {
      value: 'TN',
      label: 'Tunisia',
    },
    {
      value: 'TR',
      label: 'Turkey',
    },
    {
      value: 'TM',
      label: 'Turkmenistan',
    },
    {
      value: 'TC',
      label: 'Turks and Caicos Islands',
    },
    {
      value: 'TV',
      label: 'Tuvalu',
    },
    {
      value: 'UG',
      label: 'Uganda',
    },
    {
      value: 'UA',
      label: 'Ukraine',
    },
    {
      value: 'AE',
      label: 'United Arab Emirates',
    },
    {
      value: 'UM',
      label: 'United States Minor Islands',
    },
    {
      value: 'UY',
      label: 'Uruguay',
    },
    {
      value: 'UZ',
      label: 'Uzbekistan',
    },
    {
      value: 'VU',
      label: 'Vanuatu',
    },
    {
      value: 'VE',
      label: 'Venezuela',
    },
    {
      value: 'VN',
      label: 'Vietnam',
    },
    {
      value: 'VG',
      label: 'Virgin Islands, British',
    },
    {
      value: 'VI',
      label: 'Virgin Islands, U.S.',
    },
    {
      value: 'WF',
      label: 'Wallis and Futuna',
    },
    {
      value: 'EH',
      label: 'Western Sahara',
    },
    {
      value: 'YE',
      label: 'Yemen',
    },
    {
      value: 'ZM',
      label: 'Zambia',
    },
    {
      value: 'ZW',
      label: 'Zimbabwe',
    },
  ],
};
