import {
  ref,
  watch,
  computed,
  reactive,
  readonly,
} from 'vue';
import Http from '@/services/http';
import useUtils from '@/use/utils';
import useEmail from '@/use/email';
import useDomo from '@/use/domo';

const { hasValue } = useUtils();
const { prePopEmail } = useEmail();
const { prePopUffid } = useDomo();

// Properties
const isSessionLoaded = ref(false);
const session = reactive({});


// Set Session Property
function setSessionProp(propName, value) {
  const pluckValue = session[propName]?.value !== undefined;

  if (pluckValue) {
    session[propName].value = value;
  } else {
    session[propName] = value;
  }
}


// Pre-pop Session
async function prePopSession(flow, domoEnv, appstoreEnv, route) {
  let prePopRedirect = null;
  let remoteSessionData = null;

  // 1. Pre-pop constants
  // @TODO move flow-specific pre-pop constants to flow config file
  setSessionProp('start_type', flow.id);
  setSessionProp('domoId', `${domoEnv.domoId}` || null);
  setSessionProp('country', domoEnv?.country || '');
  setSessionProp('appstore', appstoreEnv ?? null);
  setSessionProp('university', null);
  setSessionProp('eloqua_data', {
    value: {
      elqFormName: flow.eloqua?.formName,
      Campaign_ID: flow.eloqua?.campaignId,
    },
  });
  setSessionProp('affiliateCode', route.query?.ref ?? null);

  // 2. Pre-pop email (i.e. Auto-submit email using query param if provided)
  const {
    prePopEmailRedirect,
  } = await prePopEmail(flow, session, route);

  if (prePopEmailRedirect) {
    prePopRedirect = prePopEmailRedirect;

    return {
      session,
      prePopRedirect,
    };
  }

  // 3. Pre-pop uffid (i.e. Fetch session using uffid query param if provided)
  const uffidRemoteSessionData = await prePopUffid(session, route);

  if (uffidRemoteSessionData) {
    remoteSessionData = uffidRemoteSessionData;

    const { uffid, ...queryWithoutUffid } = route.query ?? {};

    prePopRedirect = {
      name: 'step',
      params: {
        lang: route.params.lang || '',
        flowName: route.params.flowName,
        stepName: remoteSessionData.confirmationCode ? 'profile' : 'confirm',
      },
      query: queryWithoutUffid,
      replace: true,
    };
  }

  // 4. Pre-pop session (i.e. Fetch session using cookie)
  if (!remoteSessionData) {
    try {
      const { data } = await Http.get('/api/free/session');
      remoteSessionData = data;
    } catch (error) {
      console.error('ERROR PRE-POPPING COOKIE SESSION: ', error);
    }
  }

  // 5. Assign remote session to local session
  if (remoteSessionData) {
    Object.keys(remoteSessionData).forEach((sessionProp) => {
      setSessionProp(sessionProp, remoteSessionData[sessionProp]);
    });
  }

  /*
   * @NOTE "authorizeStep" method will use pre-popped session to
   * redirect where user should be in flow
   */

  // 7. Return session and redirect
  return {
    session,
    prePopRedirect,
  };
}


// Load Session
async function loadSession(flow, domoEnv, appstoreEnv, route) {
  let sessionRedirect = null;

  if (isSessionLoaded.value) {
    return {
      session,
      sessionRedirect,
    };
  }

  // 1. Provision session object based off of flow fields
  flow.steps.forEach((step) => {
    step.fields.forEach(({
      name,
      isOptional,
      isOptionalIf,
      validatorClientSide,
      ...rest
    }) => {
      session[name] = {
        isValidClientSide: computed(() => {
          const isFieldOptional = isOptional
            || (!!isOptionalIf && isOptionalIf(session));

          const isValue = hasValue(session[name].value);

          if (isFieldOptional && !isValue) return true;

          return validatorClientSide
            ? isValue && validatorClientSide(session)
            : isValue;
        }),
        ...rest,
      };
    });
  });

  // 2. Pre-pop session object
  try {
    const { prePopRedirect } = await prePopSession(flow, domoEnv, appstoreEnv, route);
    sessionRedirect = prePopRedirect;
  } catch (error) {
    console.error('ERROR PRE-POPPING SESSION', error);
  }

  // 3. Mark as loaded
  isSessionLoaded.value = true;

  // 4. Return session & redirect
  return {
    session,
    sessionRedirect,
  };
}


// Save Session
async function saveSession() {
  /*
   * @NOTE saveSession is manually being called on individual
   * step submits. When steps are templatized, it can be automated.
   */
  const sessionObjToSave = {};

  Object.keys(session).forEach((fieldName) => {
    if (session[fieldName]?.omitFromSession) return;

    const pluckValue = session[fieldName]?.value !== undefined;

    sessionObjToSave[fieldName] = pluckValue
      ? session[fieldName].value
      : session[fieldName];
  });

  try {
    await Http.post('/api/free/v2/updateSession', sessionObjToSave);
  } catch (error) {
    console.error('ERROR UPDATING SESSION: ', error);
  }
}


// Watchers
watch(
  () => session.country?.value,
  () => setSessionProp('consent', null),
);


// Exports
export default function useSession() {
  return {
    session,
    loadSession,
    saveSession,
    isSessionLoaded: readonly(isSessionLoaded),
  };
}
